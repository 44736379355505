<template lang="pug">
    .admin-menu(:class="{'admin-menu_active': dropdown}" @click="dropdown = !dropdown" v-click-outside="closeDropdown")
        ._auth
            ._group
                ._info 
                    ._name Админ-панель
            svg-icon(icon-name="chevron-down")._chevron-down
            ._dropdown
                ._dropdown-item
                    a(:href="statsBaseUrl + '/user-stats/' + currentPersonUserId" target="_blank" v-if="isAdmin")._dropdown-item-text
                        span Статистика
                    span &nbsp; | &nbsp;
                    a(:href="statsBaseUrl + '/glazomer/' + personUuid + '?externalId=' + personExternalId" target="_blank")._dropdown-item-text
                        span Глазомер
                    span &nbsp; | &nbsp;
                    router-link(:to="/person-analyze/+personUuid")._dropdown-item-text
                        span Описание 
                ._dropdown-item(@click="personSwitchPublicStatus")
                    ._dropdown-item-text(v-if="!adminItemsLoader.publicStatusSwitchLoader")
                        span(v-if="isPublicPerson === true") Сделать личность приватной
                        span(v-else) Сделать личность публичной
                    ._dropdown-item-text(v-else) Сохранение...
                label(for="adminAvatar")._dropdown-item(@change="uploadAdminPhoto")
                    input(type="file" id="adminAvatar" name="adminAvatar" :data-uuid="personUuid" accept="image/*")
                    ._dropdown-item-text(v-if="!adminItemsLoader.celebrityStatusSwitchLoader")
                        span(v-if="!adminAvatar.loading") Загрузить новый аватар
                        span(v-else) Пожалуйста подождите...
                label(for="adminAnfas")._dropdown-item(@change="uploadAdminPhoto")
                    input(type="file" id="adminAnfas" name="adminAnfas" :data-uuid="personUuid" accept="image/*")
                    ._dropdown-item-text(v-if="!adminItemsLoader.celebrityStatusSwitchLoader")
                        span(v-if="!adminAnfas.loading") Загрузить новый анфас
                        span(v-else) Пожалуйста подождите...
                label(for="adminProfile")._dropdown-item(@change="uploadAdminPhoto")
                    input(type="file" id="adminProfile" name="adminProfile" :data-uuid="personUuid" accept="image/*")
                    ._dropdown-item-text(v-if="!adminItemsLoader.celebrityStatusSwitchLoader")
                        span(v-if="!adminProfile.loading") Загрузить новый профиль
                        span(v-else) Пожалуйста подождите...
                ._dropdown-item(@click="personSwitchCelebrityStatus")
                    ._dropdown-item-text(v-if="!adminItemsLoader.celebrityStatusSwitchLoader")
                        span(v-if="isCelebrity === true") Сделать личность обычной
                        span(v-else) Сделать личность знаменитостью
                    ._dropdown-item-text(v-else) Сохранение...
                ._dropdown-item(@click="personCopyExternalId")
                    ._dropdown-item-text Скопировать ID:
                        input#externalId(:value="personExternalId" autocomplete="off" readonly)._external-id-input
                ._dropdown-item(@click="promptToChangePersonType")
                    ._dropdown-item-text(v-if="!adminItemsLoader.changePersonType") Изменить тип
                    ._dropdown-item-text(v-else) Сохранение...
                ._dropdown-item(@click="promptToSetUserSubscriptionPlan(userUuid)")
                    ._dropdown-item-text(v-if="!adminItemsLoader.setUserSubscriptionPlan") Добавить пользователю подписки
                    ._dropdown-item-text(v-else) Сохранение...
                ._dropdown-item(@click="promptChangeUserPhone")
                    ._dropdown-item-text Номер: {{ newPhone ? newPhone : currentPerson.assigned_user_phone }}
                ._dropdown-item(v-if="!isMyProfile && userUuid" @click="addReferral(userUuid)")
                    ._dropdown-item-text Сделать рефералом
                ._dropdown-item(@click="promptUnsubscribeUserFromPlans(userUuid)")
                    ._dropdown-item-text(v-if="!adminItemsLoader.deleteUserSubscriptionPlan") Удалить подписку
                    ._dropdown-item-text(v-else) Сохранение...
                ._dropdown-item(v-if="!userUuid" @click="personDelete")
                    ._dropdown-item-text Удалить личность
                ._dropdown-item(v-if="!isMyProfile && userUuid" @click="userDelete(userUuid)")
                    ._dropdown-item-text Удалить пользователя




</template>

<script>
import { menuMixins } from "../../../../mixins/menuMixins";
import { mapGetters } from "vuex";
import { photoUploadMixins } from "../../../../mixins/photoUploadMixins";
import { descriptionMixins } from "../../../../mixins/descriptionMixins";
import gql from "graphql-tag";
import personSetAssignedUserPhoneMutation from "@/graphql/mutations/personSetAssignedUserPhone.mutation.gql";
import createReferralFromDashboardMutation from "@/graphql/mutations/createReferralFromDashboard.mutation.gql";


export default {
    name: "AdminMenu",
    mixins: [photoUploadMixins, descriptionMixins],
    data: () => ({
        dropdown: false,
        adminItemsLoader: {
            publicStatusSwitchLoader: false,
            celebrityStatusSwitchLoader: false,
            changePersonType: false,
            setUserSubscriptionPlan: false,
            deleteUserSubscriptionPlan: false,
        },
        uploadedPhoto: "",
        adminAnfas: {
            loading: false,
        },
        adminProfile: {
            loading: false,
        },
        adminAvatar: {
            loading: false,
        },
        taskInterval: "",
        statsBaseUrl: "https://dashboard.merlinface.com",
        planIds: {
            1: "Профессия и самореализация, GOLD на 1 месяц",
            2: "Профессия и самореализация, GOLD на 1 год",
            3: "Отношения и семья, GOLD на 1 месяц",
            4: "Отношения и семья, GOLD на 1 год",
            5: "Мотивация и энергия, GOLD на 1 месяц",
            6: "Мотивация и энергия, GOLD на 1 год",
            7: "Самооценка и уверенность, GOLD на 1 месяц",
            8: "Самооценка и уверенность, GOLD на 1 год",
            9: "Профессия и самореализация, PLATINUM на 1 месяц",
            10: "Профессия и самореализация, PLATINUM на 1 год",
            11: "Отношения и семья, PLATINUM на 1 месяц",
            12: "Отношения и семья, PLATINUM на 1 год",
            13: "Мотивация и энергия, PLATINUM на 1 месяц",
            14: "Мотивация и энергия, PLATINUM на 1 год",
            15: "Самооценка и уверенность, PLATINUM на 1 месяц",
            16: "Самооценка и уверенность, PLATINUM на 1 год",
            17: "Профессия и самореализация, FREE",
            18: "Отношения и семья, FREE",
            19: "Мотивация и энергия, FREE",
            20: "Самооценка и уверенность, FREE",
            25: "?",
            26: "Оператор нейросетей, PLATINUM со скидкой",
            27: "Оператор нейросетей, GOLD со скидкой",
            31: "Оператор нейросетей, FREE",
            32: "Оператор нейросетей, PLATINUM",
            33: "Оператор нейросетей, GOLD",
        },
        newPhone: 0,
    }),
    computed: {
        ...mapGetters({
            isPublicPerson: "person/isPublicPerson",
            isCelebrity: "person/isCelebrity",
            currentPersonUuid: "person/uuid",
            currentPersonUserId: "person/userUuid",
            personExternalId: "person/externalId",
            userUuid: "person/userUuid",
        }),
        anfasPhoto: (state) => state.$store.getters["person/photos"].anfas,
        profilePhoto: (state) => state.$store.getters["person/photos"].profile,
        personUuid: (state) => state.$store.getters["person/uuid"],
    },
    methods: {
        async uploadAdminPhoto({ target }) {
            await this.uploadPhoto({ target }).then((r) => {
                console.log(r);
            });
        },
        closeDropdown: function () {
            if (this.dropdown) {
                this.dropdown = false;
            }
        },
        async personSwitchPublicStatus() {
            this.adminItemsLoader.publicStatusSwitchLoader = true;
            await this.$apollo
                .mutate({
                    mutation: menuMixins.mutations.personSwitchPublicStatus,
                    variables: {
                        uuid: this.currentPersonUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.adminItemsLoader.publicStatusSwitchLoader = false;
                    const newStatus = this.isPublicPerson !== true;
                    this.$store.dispatch(
                        "person/setPersonPublicStatus",
                        newStatus,
                    );
                })
                .catch(() => {
                    this.adminItemsLoader.publicStatusSwitchLoader = false;
                    this.$showError(
                        "Не удалось изменить статус, возможно вы не обладаете соотвествующими правами.",
                    );
                });
        },
        async personSwitchCelebrityStatus() {
            this.adminItemsLoader.celebrityStatusSwitchLoader = true;
            await this.$apollo
                .mutate({
                    mutation: menuMixins.mutations.personSwitchCelebrityStatus,
                    variables: {
                        uuid: this.currentPersonUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.adminItemsLoader.celebrityStatusSwitchLoader = false;
                    const newStatus = this.isCelebrity !== true;
                    this.$store.dispatch(
                        "person/setPersonCelebrityStatus",
                        newStatus,
                    );
                })
                .catch(() => {
                    this.adminItemsLoader.celebrityStatusSwitchLoader = false;
                    this.$showError(
                        "Не удалось изменить статус, возможно вы не обладаете соотвествующими правами.",
                    );
                });
        },
        personCopyExternalId() {
            let input = document.querySelector("#externalId");
            input.select();

            try {
                document.execCommand("copy");
                input.blur();
            } catch (e) {
                this.$showError(
                    "Не удалось скопировать идентификатор! Пожалуйста, выделите его вручную и скопируйте.",
                );
            }
        },
        async promptToChangePersonType() {
            let newType = prompt("Укажите новый тип (3 буквы, например ЭИЭ)");

            if (typeof newType !== "undefined" && newType.length === 3) {
                this.adminItemsLoader.changePersonType = true;

                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.personChangeType,
                        variables: {
                            uuid: this.currentPersonUuid,
                            newType,
                        },
                    })
                    .then((response) => {
                        this.adminItemsLoader.changePersonType = false;
                        const success = response.data.personChangeType;

                        if (success === true) {
                            alert(
                                "Тип обновлен! Обновите страницу чтобы изменения вступили силу.",
                            );
                        }
                    })
                    .catch((e) => {
                        this.adminItemsLoader.changePersonType = false;

                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            this.$msgBox(
                                codeError,
                                "Указан несуществующий тип, либо у вас нет прав.",
                            );
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        async promptChangeUserPhone() {
            const newPhone = prompt("Укажите новый номер телефона");
            if (newPhone) {
                await this.$apollo
                    .mutate({
                        mutation: gql(personSetAssignedUserPhoneMutation),
                        variables: {
                            personId: this.currentPerson.uuid,
                            phoneNumber: newPhone,
                        },
                    })
                    .then(() => {
                        this.$msgBox(
                            "Успешно",
                            `Старый номер ${this.currentPerson.assigned_user_phone} изменён на ${newPhone}`,
                            "success",
                        );
                        this.newPhone = newPhone;
                    })
                    .catch((e) => {
                        this.$msgBox("Ошибка", e.graphQLErrors[0].message);
                    });
            }
        },
        async promptToSetUserSubscriptionPlan(uuid, force, planId) {
            if (typeof planId === "undefined") {
                let promptText = "Укажите номер подписки:\n";

                for (let [key, value] of Object.entries(this.planIds)) {
                    promptText += `${key} - ${value}\n`;
                }

                planId = prompt(promptText);
            }

            planId = parseInt(planId);
            force = typeof force !== "undefined";

            if (planId > 0) {
                this.adminItemsLoader.setUserSubscriptionPlan = true;

                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.setUserSubscriptionPlan,
                        variables: {
                            uuid,
                            planId,
                            force,
                        },
                    })
                    .then((response) => {
                        this.adminItemsLoader.setUserSubscriptionPlan = false;
                        const result = response.data.setUserSubscriptionPlan;

                        if (result === "OK") {
                            alert(
                                "Выбранный план успешно добавлен для пользователя.",
                            );
                        } else if (result === "WARNING") {
                            if (
                                confirm(
                                    "У пользователя уже есть подписка на эту категорию" +
                                        ", вы точно хотите продолжить?",
                                )
                            ) {
                                this.promptToSetUserSubscriptionPlan(
                                    uuid,
                                    force,
                                    planId,
                                );
                            }
                        } else if (result === "EXIST") {
                            alert(
                                "У пользователя уже есть подписка на эту категорию.",
                            );
                        } else {
                            alert("Неизвестная ошибка.");
                            console.error(response);
                        }
                    })
                    .catch((e) => {
                        this.adminItemsLoader.setUserSubscriptionPlan = false;

                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            this.$msgBox(codeError, "Неизвестная ошибка.");
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        async promptUnsubscribeUserFromPlans(uuid, force, planId) {
            if (typeof planId === "undefined") {
                let promptText = "Активные подписки пользователя:\n";

                await this.$apollo
                    .query({
                        query: menuMixins.queries.getUserSubscriptions,
                        variables: {
                            uuid,
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then((response) => {
                        console.log(response.data);
                        for (let value of response.data.getUserSubscriptions) {
                            if (this.planIds[value].indexOf(", FREE") !== -1) {
                                continue;
                            }

                            promptText += `${value} - ${this.planIds[value]}\n`;
                        }
                    });

                promptText +=
                    "Укажите номер подписки, который нужно удалить:\n";
                planId = prompt(promptText);
            }

            planId = parseInt(planId);
            force = typeof force !== "undefined";

            if (planId > 0) {
                this.adminItemsLoader.deleteUserSubscriptionPlan = true;

                await this.$apollo
                    .mutate({
                        mutation:
                            menuMixins.mutations.deleteUserSubscriptionPlan,
                        variables: {
                            uuid,
                            planId,
                        },
                    })
                    .then((response) => {
                        this.adminItemsLoader.deleteUserSubscriptionPlan = false;
                        const result = response.data.deleteUserSubscriptionPlan;

                        if (result === "OK") {
                            alert("Пользователь успешно отписан.");
                        } else {
                            alert("Неизвестная ошибка.");
                            console.error(response);
                        }
                    })
                    .catch((e) => {
                        this.adminItemsLoader.deleteUserSubscriptionPlan = false;

                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            this.$msgBox(codeError, "Неизвестная ошибка.");
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        planNumberToText(id) {
            if (id === 1) {
                return "Профессия и самореализация, GOLD на 1 месяц";
            } else if (id === 2) {
                return "Профессия и самореализация, GOLD на 1 год";
            } else if (id === 2) {
                return "Отношения и семья, GOLD на 1 месяц";
            } else if (id === 2) {
                return "Профессия и самореализация, GOLD на 1 год";
            } else if (id === 2) {
                return "Профессия и самореализация, GOLD на 1 год";
            } else if (id === 2) {
                return "Профессия и самореализация, GOLD на 1 год";
            } else if (id === 2) {
                return "Профессия и самореализация, GOLD на 1 год";
            }

            /* + "1 - Профессия и самореализация, GOLD на 1 месяц\n"
                + "2 - Профессия и самореализация, GOLD на 1 год\n"
                + "3 - Отношения и семья, GOLD на 1 месяц\n"
                + "4 - Отношения и семья, GOLD на 1 год\n"
                + "5 - Мотивация и энергия, GOLD на 1 месяц\n"
                + "6 - Мотивация и энергия, GOLD на 1 год\n"
                + "7 - Самооценка и уверенность, GOLD на 1 месяц\n"
                + "8 - Самооценка и уверенность, GOLD на 1 год\n"
                + "9 - Профессия и самореализация, PLATINUM на 1 месяц\n"
                + "10 - Профессия и самореализация, PLATINUM на 1 год\n"
                + "11 - Отношения и семья, PLATINUM на 1 месяц\n"
                + "12 - Отношения и семья, PLATINUM на 1 год\n"
                + "13 - Мотивация и энергия, PLATINUM на 1 месяц\n"
                + "14 - Мотивация и энергия, PLATINUM на 1 год\n"
                + "15 - Самооценка и уверенность, PLATINUM на 1 месяц\n"
                + "16 - Самооценка и уверенность, PLATINUM на 1 год\n"
                + "17 - Профессия и самореализация, FREE\n"
                + "18 - Отношения и семья, FREE\n"
                + "19 - Мотивация и энергия, FREE\n"
                + "20 - Самооценка и уверенность, FREE\n"*/
        },
        async personDelete(uuid, personIndex, categoryIndex) {
            if (
                !this.currentPersonUuid ||
                this.currentPersonUuid.length !== 36
            ) {
                return;
            }

            let agree = confirm("Вы действительно хотите удалить личность?");
            if (agree) {
                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.personDelete,
                        variables: {
                            uuid: this.currentPersonUuid,
                        },
                        fetchPolicy: "no-cache",
                    })
                    .then((response) => {
                        const success = response.data.personDelete;
                        if (success === true) {
                            this.categories[categoryIndex].persons.splice(
                                personIndex,
                                1,
                            );
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            alert(codeError);
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        async userDelete(uuid) {
            let agree = confirm(
                "Вы действительно хотите удалить пользователя и все его данные со всех сервисов?",
            );
            if (agree) {
                let full = confirm("Удалить черты лица пользовтеля полностью?");

                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.userDelete,
                        variables: {
                            uuid: uuid,
                            full: full,
                        },
                    })
                    .then((response) => {
                        const success = response.data.userDelete;

                        if (success === true) {
                            alert("Пользователь удален!");
                            this.$router.push("/");
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            alert(codeError);
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        async addReferral(uuid) {
            const newPhone = prompt("Укажите номер телефона реферала");
            if (newPhone) {
                await this.$apollo
                    .mutate({
                        mutation: gql(createReferralFromDashboardMutation),
                        variables: {
                            referrer_user_id: uuid,
                            phone: newPhone,
                        },
                    })
                    .then((response) => {
                        if (response.data.createReferralFromDashboard.success) {
                            this.$msgBox(
                                "Успешно",
                                response.data.createReferralFromDashboard.message,
                                "success",
                            );
                            this.newPhone = newPhone;
                        } else {
                            this.$msgBox(
                                "Ошибка",
                                response.data.createReferralFromDashboard.message,
                                "error",
                            );
                        }
                    })
                    .catch((e) => {
                        this.$msgBox("Ошибка", e.graphQLErrors[0].message);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import "admin-menu";
</style>
